import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTrans } from "../../hooks";
import EmailLogin from "./EmailLogin";
import ThirdPartyLogin from "./ThirdPartyLogin";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";
import classNames from "classnames";

const LoginForm = ({
  focus = true,
  msg,
  nextUrl = "/home",
  notice,
  toggleForm
}) => {
  const { trans } = useTrans();
  const [isEmailFormView, setIsEmailFormView] = useState(false);

  const toggleFormView = () => setIsEmailFormView(!isEmailFormView);
  return (
    //NOTE: This is a temporary div, we can add CSS to login-form after rollout
    <div
      className={classNames("auth-form-padding", {
        "auth-form-modal-padding": nextUrl && !nextUrl.includes("home")
      })}
    >
      <div id="authentication-panel" className={classNames("login-form")}>
        {!isEmailFormView && (
          <p className="title">{trans("Log in to Wattpad")}</p>
        )}

        <div className="panel-body">
          {isEmailFormView ? (
            <EmailLogin
              msg={msg}
              nextUrl={nextUrl}
              notice={notice}
              toggleFormView={toggleFormView}
            />
          ) : (
            <ThirdPartyLogin
              focus={focus}
              msg={msg}
              nextUrl={nextUrl}
              notice={notice}
              toggleForm={toggleForm}
              toggleFormView={toggleFormView}
            />
          )}
        </div>
        {!isEmailFormView && (
          <Button
            variant={ButtonVariant.TERTIARY}
            className={"footer-button-margin-signup"}
            onClick={toggleForm}
            label={trans("Don't have an account? Sign up")}
            fullWidth
          />
        )}
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  focus: PropTypes.bool,
  msg: PropTypes.string,
  nextUrl: PropTypes.string,
  notice: PropTypes.string,
  toggleForm: PropTypes.func
};

export default LoginForm;
