import PropTypes from "prop-types";
import React from "react";
import { useTrans } from "../../hooks";
import ThirdPartyAuth from "./ThirdPartyAuth";
import classNames from "classnames";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";

const ThirdPartySignup = ({
  focus,
  nextUrl = "/home",
  toggleForm,
  toggleFormView
}) => {
  const { trans } = useTrans();

  const onEmailSignup = () => {
    window.te.push("event", "landing", null, null, "signup", {
      platform: "email"
    });
    toggleFormView();
  };

  return (
    <>
      <ThirdPartyAuth source="signup" nextUrl={nextUrl} focus={focus} />
      <div className={classNames("hr-with-text", "hr-margin-new")}>
        <div className="horizontal-line" />
        <span className="or"> {trans("or")} </span>
        <div className="horizontal-line" />
      </div>
      <button
        className={classNames(
          "btn-block btn-primary submit-btn",
          "submit-btn-margin-24"
        )}
        onClick={onEmailSignup}
      >
        {trans("Sign up with email")}
      </button>
      <Button
        variant={ButtonVariant.TERTIARY}
        onClick={toggleForm}
        label={trans("I already have an account")}
        fullWidth
      />
    </>
  );
};

ThirdPartySignup.propTypes = {
  focus: PropTypes.bool,
  nextUrl: PropTypes.string,
  toggleForm: PropTypes.func.isRequired,
  toggleFormView: PropTypes.func.isRequired
};

export default ThirdPartySignup;
