import React from "react";
import PropTypes from "prop-types";
import { useTrans } from "../../hooks";
import ConnectImage from "../../shared-components/ConnectImage";

import { GoogleAuth } from "./GoogleAuth";
import classNames from "classnames";

function ThirdPartyAuth({ source, nextUrl, focus = true }) {
  const { trans } = useTrans();
  const fbCta =
    source === "signup"
      ? trans("Sign up with Facebook")
      : trans("Log in with Facebook");

  const googleCta =
    source === "signup"
      ? trans("Sign up with Google")
      : trans("Log in with Google");
  return (
    /*
      TODO: autoFocus is used as a hack currently to avoid accessibility issues with modals. 
      Remove it once we have a guild-wide solution for keyboard and screen reader trapping. 
    */
    <div className={classNames("signin-buttons", "signin-buttons-new")}>
      <GoogleAuth googleCta={googleCta} nextUrl={nextUrl} source={source} />
      <button
        className="btn btn-facebook btn-block auth-button-new"
        autoFocus={focus}
        data-source={source}
      >
        <ConnectImage
          name="/shared/facebook-login-color.png"
          title="Facebook Login"
          width="18"
          className="google-login-icon"
          aria-hidden="true"
        />
        <span>
          <span className="auth-button-text-new">{fbCta}</span>
        </span>
      </button>
    </div>
  );
}
ThirdPartyAuth.propTypes = {
  source: PropTypes.string,
  nextUrl: PropTypes.string,
  focus: PropTypes.bool
};
export default ThirdPartyAuth;
