import React from "react";
import PropTypes from "prop-types";
import { Icon } from "./";
import classNames from "classnames";

/**
 * A fully accessibile checkbox.
 * @param onChange Function to be called when checkbox changes
 * @param checked current state for checkbox
 * @param value gives custom value to checkbox. Browser defaults to 'on'
 * @param children The checkbox's children which will appear as its label
 * @param contentId An unique id for the checkbox's content, used for a11y.
 * @param name The name attribute for the checkbox input
 * @param disabled Specify whether the Checkbox should be disabled
 * @param required Specify whether the Checkbox should be required
 * @param id An unique Id for the checkbox input
 * @param onBlur Function to be called when checkbox loses focus
 * @param classname Custom classname
 */
const NewCheckbox = ({
  onChange,
  onBlur,
  checked,
  value,
  children,
  contentId,
  name,
  disabled,
  required,
  id,
  invalid,
  classname
}) => {
  const onChangeHandler = event => {
    onChange(event);
  };

  return (
    <label
      className={classNames(
        "c-checkbox",
        {
          "label-active": checked
        },
        classname
      )}
    >
      <input
        type="checkbox"
        id={id}
        onChange={onChangeHandler}
        value={value}
        name={name}
        checked={checked}
        disabled={disabled}
        required={required}
        aria-labelledby={contentId}
        onBlur={onBlur}
      />
      <div
        className={classNames({
          checked: checked,
          ["invalid"]: invalid === false
        })}
      >
        {checked && (
          <Icon
            name="check"
            color="wp-neutral-5"
            size={14}
            className="check-icon"
          />
        )}
        {children && (
          <div className="c-checkbox__content" id={contentId} aria-hidden>
            {children}
          </div>
        )}
      </div>
    </label>
  );
};

NewCheckbox.defaultProps = {
  onChange: () => {},
  checked: false,
  disabled: false
};

NewCheckbox.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  value: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  contentId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  invalid: PropTypes.bool,
  classname: PropTypes.string
};

export default NewCheckbox;
