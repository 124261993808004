import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ValidationElements extends Component {
  render() {
    if (
      this.props.isValid === false &&
      this.props.name !== "month" &&
      this.props.name !== "day" &&
      this.props.name !== "year"
    ) {
      return (
        <div
          aria-live="assertive"
          className={classNames("error-msg")}
          role="alert"
        >
          {this.props.errorMessage}
        </div>
      );
    } else {
      return null;
    }
  }
  static propTypes = {
    name: PropTypes.string,
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string
  };
}
