import PropTypes from "prop-types";
import React from "react";
import { Icon } from "../../shared-components";
import { useTrans } from "../../hooks";
import ThirdPartyAuth from "./ThirdPartyAuth";
import classNames from "classnames";
import { Button, ButtonVariant } from "@wattpad/web-ui-library";

const ThirdPartyLogin = ({
  focus,
  msg,
  nextUrl = "/home",
  notice,
  toggleFormView
}) => {
  const { trans } = useTrans();

  const onEmailLogin = () => {
    window.te.push("event", "landing", null, null, "login", {
      platform: "email"
    });
    toggleFormView();
  };

  return (
    <>
      <ThirdPartyAuth source="login" nextUrl={nextUrl} focus={focus} />
      <div className={classNames("hr-with-text", "hr-margin-new")}>
        <div className="horizontal-line" />
        <span className="or"> {trans("or")} </span>
        <div className="horizontal-line" />
      </div>
      <button
        className={classNames("btn-block btn-primary", "submit-btn-new")}
        onClick={onEmailLogin}
      >
        {trans("Log in with email")}
      </button>
      <Button
        variant={ButtonVariant.TERTIARY}
        href={`/forgot?nextUrl=${nextUrl}`}
        label={trans("Forgot password?")}
        fullWidth
      />
      {msg && (
        <div className="alert alert-danger" role="alert">
          <Icon iconName="fa-info" height="16" color="wp-lighterror" />
          {msg}
        </div>
      )}
      {notice && (
        <div className="alert alert-info" role="alert">
          <Icon iconName="fa-info" height="16" color="wp-neutral-2" />
          {notice}
        </div>
      )}
    </>
  );
};

ThirdPartyLogin.propTypes = {
  focus: PropTypes.bool,
  msg: PropTypes.string,
  nextUrl: PropTypes.string,
  notice: PropTypes.string,
  toggleFormView: PropTypes.func
};

export default ThirdPartyLogin;
